@font-face {
    font-family: Klavika;
    src: url("../../assets/font/klavika-light.otf") format("opentype");
}

:root {
    /* Common styles */
    --color-red: #FF0000;
    --color-white: #FFFFFF;
    --color-black: #000000;
    --color-blue:  #198DFF;
    --color-grey1: #F4F6F9;
    --color-green: #00FF00;
    /* Background colors */
    --color-bg-main: #FCFCFC;
    --color-bg-primary: #FFFFFF;
    --color-bg-sidebar: #FFFFFF;
    /* Typography colors */
    --color-text-main: #000000;
    --color-text-secondary: #525862;
    --color-placeholder: #525862;
    --color-border-main: #EAEAEA;
}

body * {
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
}

div, a, span, p, h1, h2, h3, h4, h5, h6 {
    margin-bottom: 0px;
    font-family: Klavika;
}
