@import 'assets/styles/Global.scss';

body {
    background-color: rgb(32, 39, 45) !important;
    /* width */
    &::-webkit-scrollbar {
        width: 2px;
        height: 5px;
    }
    
    /* Track */
    &::-webkit-scrollbar-track {
        background: #F6F6F6; 
    }
    
    /* Handle */
    &::-webkit-scrollbar-thumb {
        background: gray;
    }
    
    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
        background: #555; 
    }
}
.switch-wrapper {
    position: relative;
}

.invalid_msg {
    color: #FF0000;
}
.success_msg {
    color:  var(--color-green);
}
.scrollbar {
    /* width */
      &::-webkit-scrollbar {
        width: 2px;
        height: 5px;
    }
    
    /* Track */
    &::-webkit-scrollbar-track {
        background: #F6F6F6; 
    }
    
    /* Handle */
    &::-webkit-scrollbar-thumb {
        background: #D8D8D8;
    }
    
    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
        background: #D8D8D8; 
    }
}

.ant-popover-placement-topLeft {
    top: calc(100vh - 220px) !important;
}

.Toastify__toast-container {
    width: auto;
    padding: 0 !important;
  
    .Toastify__toast {
      // width: 100vw;
      min-height: 52px;
      // left: 0;
      padding: 0 !important;
      border-radius: 10px;
  
      .Toastify__toast-body {
        width: 100%;
        display: flex;
        align-items: center;
        margin: 0;
        color: #ffffff;
        background-color: #070D11;
        padding: 10px 20px;
        background-image: url('/congratulation.gif');
        .toast_container_wrapper {
          display: flex;
          align-items: center;
          width: 100%;
          height: 100%;
          opacity: 0.9;

          .toast-item {
              &:not(:last-child) {
                margin-right: 40px;
              }
          }
        }
  
        .success {
          background: #070D11;
        }
        .error {
          background: #f5222d;
        }
        
        .info {
          background: #FFC200;
        }
      }
  
      .Toastify__close-button {
        position: absolute;
        right: 0;
        color: #ffffff;
        opacity: 1;
        top: 15px;
        right: 25px;
        display: none;
      }
    }

    @media (max-width: 768px) {
        .toast-title {
            font-size: 24px;
        }
        .toast-item {
            img {
                width: 50px;
                height: 50px;
            }

            span {
                font-size: 14px;
            }
            &:not(:last-child) {
                margin-right: 20px;
            }
        }
    }
  }
// Common CSS
.flex {
    display: flex;
}
.flex-column {
    flex-direction: column;
}

.align-center {
    align-items: center;
}

.just-center {
    justify-content: center;
}

.just-between {
    justify-content: space-between;
}

.just-around {
    justify-content: space-around;
}

.just-end {
    justify-content: flex-end;
}
.center {
    text-align: center;
}
.left {
    text-align: left;
}
.right {
    text-align: right;
}
.pointer {
    cursor: pointer;
}
.bold {
    font-weight: bold;
}
